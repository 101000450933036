/* Styles pour les cellules avec des graphiques en ligne */
.sparkline-cell {
  padding: 0 !important;
  overflow: visible !important;
}

/* Correction des styles pour les en-têtes de colonnes */
.MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitle {
  font-weight: 600;
  white-space: pre-line;
}

/* Correction des styles pour les badges de filtres */
.MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root[data-testid="FilterListIcon"] {
  color: #1976d2 !important;
  background-color: rgba(25, 118, 210, 0.08) !important;
  border-radius: 50% !important;
  padding: 2px !important;
  font-size: 1.2rem !important;
}

/* Style pour les colonnes actuellement filtrées */
.MuiDataGrid-columnHeader[aria-sort] .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root[data-testid="FilterListIcon"] {
  color: #1976d2 !important;
  background-color: rgba(25, 118, 210, 0.16) !important;
  border-radius: 50% !important;
  padding: 2px !important;
}

/* Animation au survol */
.MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer:hover .MuiSvgIcon-root[data-testid="FilterListIcon"] {
  transform: scale(1.1) !important;
  transition: transform 0.2s ease-in-out !important;
}

/* Correction des styles pour les cellules avec des valeurs numériques */
.MuiDataGrid-cell[data-field^="brand"],
.MuiDataGrid-cell[data-field^="organic"],
.MuiDataGrid-cell[data-field^="paid"],
.MuiDataGrid-cell[data-field^="uncaptured"],
.MuiDataGrid-cell[data-field^="blended"],
.MuiDataGrid-cell[data-field^="ad"] {
  font-variant-numeric: tabular-nums !important;
}

/* Correction des styles pour les différences */
.MuiDataGrid-cell[data-field$="Diff"] {
  font-weight: 700 !important;
}

/* Correction des styles pour les valeurs comparatives */
.MuiDataGrid-cell[data-field$="Compare"] {
  opacity: 0.6 !important;
}

/* Amélioration de la visibilité des en-têtes de colonnes */
.MuiDataGrid-columnHeaderTitle {
  overflow: visible !important;
  white-space: normal !important;
  line-height: 1.2 !important;
}

/* Correction pour les erreurs de parsing font-weight et opacity */
.MuiDataGrid-root .MuiDataGrid-cell {
  font-weight: inherit !important;
}

.MuiDataGrid-root .MuiDataGrid-cell[data-field$="Diff"] {
  font-weight: 700 !important;
}

.MuiDataGrid-root .MuiDataGrid-cell[data-field$="Compare"] {
  opacity: 0.6 !important;
}

.css-1j6i4jd-MuiPaper-root-MuiAlert-root .MuiAlert-icon {
  color: white!important;
  position: relative;
  top: 10px
}

.css-uhu55t-MuiFormControlLabel-root .MuiFormControlLabel-label.Mui-disabled {
  display: none!important;
}

.css-uhu55t-MuiFormControlLabel-root.Mui-disabled {
  display: none!important;
}

.css-1oudwrl {
  z-index: -999!important
}

.MuiDataGrid-pinnedColumns, 
.MuiDataGrid-pinnedColumnHeaders {
  overflow: visible !important;     /* Permettre le dépassement */
  z-index: 1 !important;            /* Abaisser le pinned container sous le tooltip */
  position: relative !important;    /* Contrôler la stack context */
}
.apexcharts-tooltip {
  z-index: 999999 !important;       /* Maintenir un index très au-dessus */
  position: absolute !important;
}