.MuiDataGrid-filterFormLogicOperatorInput {
    visibility: visible !important;
}

/* Assurer que les opérateurs logiques sont bien visibles */
.MuiDataGrid-filterForm .MuiInputBase-root {
  opacity: 1 !important;
  visibility: visible !important;
}

/* Améliorer le contraste des sélecteurs */
.MuiDataGrid-filterForm .MuiSelect-select {
  color: inherit !important;
}

/* S'assurer que les menus déroulants sont cliquables */
.MuiDataGrid-filterForm .MuiSelect-select:not(.Mui-disabled) {
  pointer-events: auto !important;
  cursor: pointer !important;
}

/* Améliorer la visibilité du menu déroulant ouvert */
.MuiMenu-paper .MuiMenuItem-root {
  opacity: 1 !important;
}

/* Amélioration du style de la pastille de filtre sur les colonnes */
.MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root[data-testid="FilterListIcon"] {
  color: #1976d2;
  background-color: rgba(25, 118, 210, 0.08);
  border-radius: 50%;
  padding: 2px;
  font-size: 1.2rem;
}

/* Style pour les colonnes actuellement filtrées */
.MuiDataGrid-columnHeader[aria-sort] .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root[data-testid="FilterListIcon"] {
  color: #1976d2;
  background-color: rgba(25, 118, 210, 0.16);
  border-radius: 50%;
  padding: 2px;
}

/* Animation au survol */
.MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer:hover .MuiSvgIcon-root[data-testid="FilterListIcon"] {
  transform: scale(1.1);
  transition: transform 0.2s ease-in-out;
}

/* Correction du style du badge */
.MuiBadge-badge.MuiBadge-standard.MuiBadge-anchorOriginTopRight.MuiBadge-anchorOriginTopRightRectangular.MuiBadge-overlapRectangular.css-n415eq-MuiBadge-badge{
  background-color: #7635dc;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 0.75rem;
  min-width: 20px;
  height: 20px;
  border-radius: 10px;
  padding: 0 6px;
  transform: scale(1) translate(50%, -50%);
  transform-origin: 100% 0%;
}